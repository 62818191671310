import React from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import "swiper/swiper-bundle.min.css"
import SlideshowImage from "./slideshow-image"
import { useStaticQuery, graphql } from "gatsby"

const StyledSwiper = styled.div`
margin-bottom: ${props => props.theme.margins.marginSlideshow};
  /* align-items: center; */

  /* margin-top: ${props => props.theme.margins.marginLarge}; */

  /* max-width: 1200px; */
  /* max-height: 674px; */

  /* max-width: 100%; */
  /* height: calc(100vh - 16px); */

`

const StyledSlide = styled.div`
  /* align-items: center;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center; */
`

const AspectRatioDiv = styled.div`
  /* padding-bottom: 56.25%;
  width: 100%; */
`

SwiperCore.use([Navigation, Pagination])

const Slideshow = ({ foldername }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 674) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  let filenames = []

  return (
    <StyledSwiper>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        preloadImages={true}
      >
        {data.images.edges.map(({ node }) => {
          let relativePath = node.relativePath
          if (relativePath.includes(foldername)) {
            if (relativePath.includes(".png") || relativePath.includes(".jpg"))
              filenames.push(node.publicURL)
          }
        })}

        {filenames.map(file => (
          <SwiperSlide key={file}>
            <StyledSlide>
              <SlideshowImage filename={file} />
            </StyledSlide>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSwiper>
  )
}

export default Slideshow
