import React from "react"
import styled from "styled-components"

const StyledGhostbutterTagLine = styled.div`
  display: block;
  margin: 1.5em;
  margin-left: 0;
  font-family: ${props => props.theme.fonts.defaultFont};
  font-size: ${props => props.theme.fontSizes.h1};
`

export default function GhostbutterTagLine({ children }) {
  return (
    <StyledGhostbutterTagLine>
      Making spooky-cute, bone-licking good games since 2014.
    </StyledGhostbutterTagLine>
  )
}
