import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import Slideshow from "./slideshow"

import Layout from "./layout"
import MainContainer from "./main-container"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import GhostbutterTagLine from "./tagline"

const GameTitle = styled.div`
  width: auto;
  color: ${props => props.theme.colors.defaultText};
  font-family: "HermesRough";
  font-size: ${props => props.theme.fontSizes.gameTitle};
  line-height: ${props => props.theme.fontSizes.lineHeightH1};
`

export default function PageTemplate({ pageContext }) {
  const shortcode =
    "https://ghostbutter.com/games/" + pageContext.game.frontmatter.shortcode
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Ghostbutter - Games - {pageContext.game.frontmatter.title}
        </title>
        <link rel="canonical" href={shortcode} />
      </Helmet>
      <Header></Header>
      <MainContainer>
        <GameTitle>{pageContext.game.frontmatter.title}</GameTitle>
        <Slideshow foldername={pageContext.game.frontmatter.shortcode} />
        <MDXRenderer>{pageContext.game.body}</MDXRenderer>
      </MainContainer>
      <Footer></Footer>
    </Layout>
  )
}
