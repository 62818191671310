import React from "react"
import styled from "styled-components"

import Image from "./image"

const StyledImage = styled.div`
  color: ${props => props.theme.colors.defaultText};
  /* max-width: 1200px;
  max-height: 674px; */
  /* object-fit: cover; */
`

export default function SlideshowImage({ filename }) {
  return (
    <StyledImage>
      <Image filename={filename} />
    </StyledImage>
  )
}
